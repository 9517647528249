<template>
  <div id="newsStoryContent">
    <img id="Image1" class="rounded" src="@/assets/news-network/early-access-even-earlier.jpg">
    <p id="Text1">
      The LEGO® Universe community has been buzzing with anticipation, and we’ve decided to respond by letting all ‘Founders’ access the game on Friday, October 8 at 15:08:08 GMT, four days earlier than planned, and just in time for an action-packed release weekend!
      <br>
      <br>
      If you still haven’t got a copy of LEGO Universe, don’t miss your chance. Visit the LEGO Retail Store in your home town from October 8, to take home your copy of the game and your Astronaut Minifigure. Then hop in your spaceship, and blast off to the all the creative worlds in awesome LEGO Universe.
    </p>
  </div>
</template>
